const loaded = function () {
  if (this.moreButtonAdded) {
    this.loading = false
    this.moreButton.innerHTML = this.moreButtonHtml

    setTimeout(() => {
      this.moreButton.classList.remove(this.loadingClass)
    }, 100)
  }
}

export default loaded
