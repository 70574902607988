const addOpenButtonListener = function () {
  this.openButton.addEventListener('click', event => {
    if (! this.isOpen) {
      this.open()
    } else {
      this.close()
    }

    event.preventDefault()
  })
}

export default addOpenButtonListener
