import * as prototype from './prototype/prototype'

const button = core.qs('.nav-button')

const Gallery = function (id) {
  if (! (this instanceof Gallery)) {
    return new Gallery(options)
  }

  this.id = id
  this.sectionElement = core.qs(`section.${id}`)
  this.openButton = core.qs(`section.${id} .open-gallery`)
  this.galleryElement = core.qs(`section.${id} .gallery`)
  this.photosElement = core.qs(`section.${id} .photos`)
  this.photosTotalCount = Number(core.qs(`section.${id} .gallery`).getAttribute('data-num-photos'))
  this.moreButton = undefined
  this.xhrUrl = document.location.href + `${id}/0`
  this.xhrResponse = undefined
  this.isOpen = false
  this.hasBeenOpened = false
  this.scrolledIntoView = false
  this.moreButtonAdded = false
  this.loading = false
}

Object.keys(prototype).forEach(property => {
  Gallery.prototype[property] = prototype[property]
})

const galleries = []

const init = () => {
  Array.from(core.qsAll('nav a[data-type="gallery"]')).forEach((current, index) => {
    galleries.push(new Gallery(current.getAttribute('data-id')))
    galleries[index].init()
  })
}

export default init
