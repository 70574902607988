const topics = {}

const sub = (topic, listener) => {
  if (! topics[topic]) {
    topics[topic] = []
  }

  topics[topic].push(listener)
}

const unSub = (topic, listener) => {
  const index = topics[topic].indexOf(listener)

  topics[topic].splice(index, 1)
}

const pub = (topic, data) => {
  if (! topics[topic]) {
    throw new Error('no such topic exists!')
  } else {
    topics[topic].forEach(listener => {
      listener(data)
    })
  }
}

export {sub, unSub, pub}
