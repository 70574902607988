const button = core.qs('.nav-button')
const html = core.qs('html')

const show = () => {
  button.classList.add('visible')
}

const hide = () => {
  button.classList.remove('visible')
}

const open = () => {
  button.classList.add('open')
  html.classList.add('nav-open')
}

const close = () => {
  button.classList.remove('open')
  html.classList.remove('nav-open')
}

const init = () => {
  button.addEventListener('click', event => {
    if (! button.classList.contains('open')) {
      core.pub('nav/open')
      core.pub('scroll')
    } else {
      core.pub('nav/close')
      core.pub('scroll')
    }

    event.preventDefault()
  })

  core.sub('nav/nav-button/show', show)
  core.sub('nav/nav-button/hide', hide)
  core.sub('nav/open', open)
  core.sub('nav/close', close)
}

export default init
