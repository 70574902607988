import qajax from 'qajax'

const load = gallery => {
  qajax(gallery.xhrUrl)
    .then(qajax.filterSuccess)
    .then(res => {
      gallery.xhrResponse = res.response
      gallery.append()
    }, err => console.log(err))
    .done()
}

core.sub('gallery/request', load)
