import addMoreButton from './addMoreButton'
import addOpenButtonListener from './addOpenButtonListener'
import append from './append'
import close from './close'
import disableMoreButton from './disableMoreButton'
import loaded from './loaded'
import open from './open'
import opened from './opened'
import plugins from './plugins'
import scrollIntoView from './scrollIntoView'
import showPhotos from './showPhotos'

const disabledClass = 'disabled'
const hiddenClass = 'hidden'
const loadingClass = 'loading'
const moreButtonHtml = '<span>Mer bilder</span>'
const moreButtonLoadHtml = '<span>Laddar ...</span>'
const openButtonCloseHtml = '<span>Stäng bildgalleri</span>'
const openButtonHtml = '<span>Bildgalleri</span>'
const openButtonLoadHtml = '<span>Laddar ...</span>'
const visibleClass = 'visible'

const init = function () {
  this.addOpenButtonListener()
}

export {
  addMoreButton,
  addOpenButtonListener,
  append,
  close,
  disableMoreButton,
  loaded,
  open,
  opened,
  plugins,
  scrollIntoView,
  showPhotos,

  disabledClass,
  hiddenClass,
  loadingClass,
  moreButtonHtml,
  moreButtonLoadHtml,
  openButtonCloseHtml,
  openButtonHtml,
  openButtonLoadHtml,
  visibleClass,

  init
}
