const showPhotos = function () {
  const photos = Array.from(core.qsAll('.photo', this.photosElement))
  const last = photos.length - 1

  photos.forEach((photo, index) => {
    if (! photo.classList.contains(this.visibleClass)) {
      const img = core.qs('img', photo)

      img.addEventListener('load', () => {
        setTimeout(() => {
          photo.classList.add(this.visibleClass)

          if (index === last) {
            this.addMoreButton()
            this.scrollIntoView()
            this.opened()
            this.loaded()
            this.disableMoreButton()
          }
        }, 200)
      })
    }
  })
}

export default showPhotos
