import * as prototype from './prototype/prototype'

const Link = function (id, scrollTo) {
  this.link = core.qs(`nav a[data-id="${id}"]`)
  this.scrollTo = core.qs(scrollTo)
}

Object.keys(prototype).forEach(property => {
  Link.prototype[property] = prototype[property]
})

const links = []

const init = () => {
  Array.from(core.qsAll('nav a')).forEach((current, index) => {
    links.push(new Link(current.getAttribute('data-id'), current.getAttribute('data-scrollTo')))
    links[index].init()
  })
}

export default init
