const addMoreButton = function () {
  if (! this.moreButtonAdded && this.photosTotalCount > 10) {
    this.moreButtonAdded = true

    const button = core.ce('a')

    button.innerHTML = this.moreButtonHtml
    button.classList.add('button')
    button.classList.add('load-more')
    button.setAttribute('href', '')

    button.addEventListener('click', event => {
      if (! this.loading) {
        this.loading = true

        const total = this.photosTotalCount
        const current = core.qsAll('.photo', this.photosElement).length

        if (current < total) {
          button.classList.add(this.loadingClass)
          button.innerHTML = this.moreButtonLoadHtml

          this.xhrUrl = `${this.id}/${current}`

          core.pub('gallery/request', this)
        }
      }

      event.preventDefault()
    })

    this.galleryElement.appendChild(button)
    this.moreButton = button
  }
}

export default addMoreButton
