import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import baguetteBox from 'baguettebox.js'

const masonry = function () {
  const photos = `section.${this.id} .photos`

  const gallery = new Masonry(photos, {
    itemSelector: '.photo',
    percentPosition: true
  })

  imagesLoaded(photos).on('progress', () => {
    gallery.layout()
  })
}

const baguette = function () {
  const photos = `section.${this.id} .photos`
  const gallery = baguetteBox

  gallery.run(photos)
}

const plugins = function () {
  setTimeout(() => {
    masonry.call(this)
    baguette.call(this)
  }, 100)
}

export default plugins
