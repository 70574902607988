import './common/core/core'
import './common/request'
import gallery from './components/gallery/gallery'
import nav from './components/nav/nav'
import links from './components/nav/links/links'
import button from './components/nav/button/button'
import overlay from './components/nav/overlay/overlay'
import scroll from './components/nav/scroll'

window.addEventListener('load', () => {
  gallery()
  nav()
  links()
  button()
  overlay()
  scroll()
})
