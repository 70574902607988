const open = function () {
  this.isOpen = true

  if (! this.hasBeenOpened) {
    this.openButton.innerHTML = this.openButtonLoadHtml
    this.openButton.classList.add(this.loadingClass)

    core.pub('gallery/request', this)
  } else {
    this.openButton.innerHTML = this.openButtonCloseHtml
    this.photosElement.classList.remove(this.hiddenClass)
  }

  if (this.moreButtonAdded && this.photosTotalCount > 10) {
    this.moreButton.classList.remove(this.hiddenClass)
  }
}

export default open
