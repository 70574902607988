const qs = (element, source) => {
  if (source !== undefined) {
    return source.querySelector(element)
  }
  return document.querySelector(element)
}

const qsAll = (elements, source) => {
  if (source !== undefined) {
    return source.querySelectorAll(elements)
  }

  return document.querySelectorAll(elements)
}

const ce = element => {
  return document.createElement(element)
}

export {qs, qsAll, ce}
