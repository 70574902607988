const nav = core.qs('nav')

const open = () => {
  nav.classList.add('visible')
}

const close = () => {
  nav.removeAttribute('class')
}

const init = () => {
  window.addEventListener('keydown', event => {
    if (nav.classList.contains('visible') && event.keyCode === 27) {
      core.pub('nav/close')
    }
  })

  core.sub('nav/open', open)
  core.sub('nav/close', close)
}

export default init
