const overlay = core.qs('.nav-overlay')

const open = () => {
  overlay.classList.add('visible')
}

const close = () => {
  overlay.classList.remove('visible')
}

const init = () => {
  overlay.addEventListener('click', event => {
    core.pub('nav/close')

    event.preventDefault()
  })

  core.sub('nav/open', open)
  core.sub('nav/close', close)
}

export default init
