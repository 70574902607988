const opened = function () {
  if (! this.hasBeenOpened) {
    this.hasBeenOpened = true
    this.photosElement.classList.remove(this.hiddenClass)
    this.openButton.innerHTML = this.openButtonCloseHtml

    setTimeout(() => {
      this.openButton.classList.remove(this.loadingClass)
    }, 100)
  }
}

export default opened
