import links from './links'

const init = function () {
  this.link.addEventListener('click', event => {
    core.pub('nav/close')
    core.pub('scroll')

    core.scroll.to(this.scrollTo, null, () => {
      core.pub('nav/nav-button/show')
      core.pub('scroll')
    })

    event.preventDefault()
  })

  this.link.addEventListener('mouseover', event => {
    const elementIndex = this.links.indexOf(this.link)

    this.links.forEach((link, index) => {
      if (index !== elementIndex) {
        link.classList.add('inactive')
      }
    })

    event.preventDefault()
  })

  this.link.addEventListener('mouseout', event => {
    this.links.forEach(link => {
      link.classList.remove('inactive')
    })

    event.preventDefault()
  })
}

export {
  links,
  init
}
