let oldScrollPosition = document.documentElement.scrollTop || document.body.scrollTop
let activated = true

const scrollHandler = event => {
  if (activated) {
    const newScrollPosition = document.documentElement.scrollTop || document.body.scrollTop

    if (! newScrollPosition || oldScrollPosition >= newScrollPosition) {
      core.pub('nav/nav-button/show')
    } else {
      core.pub('nav/nav-button/hide')
    }

    oldScrollPosition = newScrollPosition
  }
}

const toggleActive = () => {
  if (activated) {
    window.removeEventListener('scroll', scrollHandler)

    activated = false
  } else {
    window.addEventListener('scroll', scrollHandler)

    setTimeout(() => {
      activated = true
    }, 100)
  }
}

const init = () => {
  window.addEventListener('scroll', scrollHandler)

  core.sub('scroll', toggleActive)
}

export default init
