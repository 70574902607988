const close = function () {
  this.isOpen = false
  this.openButton.innerHTML = this.openButtonHtml
  this.photosElement.classList.add(this.hiddenClass)

  if (this.moreButtonAdded && this.photosTotalCount > 10) {
    this.moreButton.classList.add(this.hiddenClass)
  }
}

export default close
