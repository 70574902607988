const disableMoreButton = function () {
  if (this.moreButtonAdded) {
    const total = this.photosTotalCount
    const current = core.qsAll('.photo', this.photosElement).length

    if (current === total) {
      setTimeout(() => {
        this.moreButton.classList.add(this.disabledClass)
      }, 100)
    }
  }
}

export default disableMoreButton
